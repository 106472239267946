import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Input } from '../../components';
import { isEmptyString } from "../../utils";
import { AuthService } from '../../services';

import styles from "./ResetPassword.module.css";


export const ForgotPassword = ({authService = new AuthService()}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [emailSent, setEmailSent] = useState<boolean>(false);
  
  const onEmailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await authService.sendForgotPasswordEmail(email);

    setEmailSent(true);
  };

  return (
    <div className={styles["forgotPassword"]}>
      <h2>{t("forgotPasswordTitle")}</h2>
      <p>{t("forgotPasswordInfo")}</p>
      <form onSubmit={handleSubmit} className={styles["forgotPasswordForm"]}>
        <div>
          <Input type="email" name="email" placeholder={t("email").toString()} value={email} onChange={onEmailChangeHandler} />
        </div>
        {!emailSent && 
        <div className={styles["forgotPasswordButton"]}>
          <Button type="submit" disabled={isEmptyString(email)}>{t("forgotPasswordSubmitButton")}</Button>
        </div>}
        {emailSent && <p>{t("forgotPasswordEmailSentConfirmation")}</p>}
      </form>
    </div>
  );
}
