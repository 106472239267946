import { getDeviceFingerprintHash } from "../utils";
import { APIResponse, BaseAPI } from "./api";

export class AuthService {
  private api: BaseAPI;

  private authUrl = "api/authentication";
  
  constructor() {
    this.api = new BaseAPI();
  }

  async login(username: string, password: string): Promise<APIResponse<boolean>> {
    const url = this.authUrl + "/login";
    const deviceFingerprint = await getDeviceFingerprintHash();
    const data = {
      username: username,
      password: password,
      deviceFingerprint: deviceFingerprint
    };
    const response = await this.api.post(url, data);
    return response;
  }

  async register(
    username: string,
    password: string,
    name: string,
    surname: string,
    professionalProfile: string,
    language: string
  ): Promise<APIResponse<boolean>> {
    const url = this.authUrl + "/register";
    const deviceFingerprint = await getDeviceFingerprintHash();
    const data = {
      username: username,
      password: password,
      name: name,
      surname: surname,
      professionalProfile: professionalProfile,
      language: language,
      deviceFingerprint: deviceFingerprint
    };
    const response = await this.api.post(url, data);
    return response;
  }

  async checkAuthentication(): Promise<APIResponse<boolean>> {
    const url = this.authUrl + "/checkAuthentication";
    const response = await this.api.get(url);
    return response;
  }

  async logout(): Promise<void> {
    const url = this.authUrl + "/logout";
    await this.api.get(url);
  }

  async professionalProfiles(): Promise<APIResponse<string[]>> {
    const url = this.authUrl + "/professionalProfiles";
    const response = await this.api.get(url);
    return response;
  }

  async sendForgotPasswordEmail(username: string): Promise<void> {
    const url = this.authUrl + "/forgotPassword";
    const data = {
      username: username
    };
    await this.api.post(url, data);
  }

  async resetPassword(
    newPassword: string,
    resetPasswordCode: string
  ): Promise<APIResponse<boolean>> {
    const url = this.authUrl + "/resetPassword";
    const data = {
      password: newPassword,
      code: resetPasswordCode
    };
    const response = await this.api.post(url, data);
    return response;
  }

  async validateExternalCode(code: string) {
    const url = this.authUrl + "/validateResetPasswordCode?code=" + code;
    const response = await this.api.get(url);
    return response;
  }
}